
    import Vue from "vue";
    import Component from "vue-class-component";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import ApiButton from "@/components/ApiButton.vue";
    import DatePicker from "@/components/DatePicker.vue"
    import { Authentication } from "@/stuff/Authentication";
    import { Workflow, IWorkflow } from "@/model/Workflow";
    import { WorkflowHistory, IWorkflowHistory } from "@/model/WorkflowHistory";
    import { IUser } from "@/model/User";
    import { UserSearchParameters } from "@/model/UserSearchParameters";
    import { TriState, UserRole, LookupGroup, WorkflowView } from "@/model/Enums";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { WorkflowSearchParameters } from "@/model/WorkflowSearchParameters"
    import * as toastr from "toastr";
    import { Prop, Watch } from "vue-property-decorator";
    import { Validations } from "vuelidate-property-decorators";

    @Component({ components: { ApiButton, DatePicker } })
    export default class Workflows extends Vue {

        async mounted():Promise<void> {
            const user = (Authentication.signedInUser() as IUser)
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.parameters.assignedToUserID = user.id;
            await this.getLookups();
            this.parameters.assignedToUserID = this.view == WorkflowView.Admin ? -1 : user.id;
            await this.search();
        }

        // properties

        @Prop({required: true}) view!: WorkflowView;

        editDialogID: string = `${utils.newGuid()}_wf_edit`;
        editHistoryID: string = `${utils.newGuid()}_wf_history`;

        isBuyerZone = false;

        allUsers: Array<ILookupItem> = [];
        adminUsers: Array<ILookupItem> = [];
        cqmsUsers: Array<ILookupItem> = [];
        supplierList: Array<ILookupItem> = [];
        moduleList: Array<ILookupItem> = [];
        categoryList: Array<ILookupItem> = [];
        
        itemsPerPage: number = 50;
        readonly parameters = new WorkflowSearchParameters()

        totalCount = -1;
        workflows: Array<Workflow> = [];

        selectedWorkflows: Array<number> = []; 
        readonly workflow = new Workflow();
        currentlyAssignedTo: number = 0;

        history: Array<WorkflowHistory> = [];

        // computed properties

        get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        get title(): string {
            if (this.view == WorkflowView.Admin) return "Admin Workflows";
            return "Audit Workflows";
        }

        get moduleOptions(): Array<ILookupItem> { 
            const options = this.moduleList.filter(m => !m.isArchived);
            return utils.selectOptions(options, "All Modules"); 
        }

        get categoryOptions(): Array<ILookupItem> {
            const options = this.categoryList.filter(c => !c.isArchived);
            return utils.selectOptions(options, "All Categories");
        }

        get userSearchOptions(): Array<ILookupItem> {
            const options: Array<ILookupItem> = [];
            const allItem = new LookupItem({ id: 0, description: "All", isArchived: false } as ILookupItem);
            options.push(allItem)
            
            if (this.view == WorkflowView.Admin) {
                const unassignedItem = new LookupItem({ id: -1, description: "Unassigned", isArchived: false } as ILookupItem);
                options.push(unassignedItem)
                options.push(...this.adminUsers);
            }
            else if (this.view == WorkflowView.Auditor) {   
                options.push(...this.cqmsUsers);
            }
            
            return options;
        }

        get allUsersOptions(): Array<ILookupItem> {
            const options: Array<ILookupItem> = [];
            const allItem = new LookupItem({ id: 0, description: "All", isArchived: false } as ILookupItem);
            options.push(allItem)
            
            const unassignedItem = new LookupItem({ id: -1, description: "Unassigned", isArchived: false } as ILookupItem);
            options.push(unassignedItem)
            options.push(...this.adminUsers);
            options.push(...this.cqmsUsers);
            
            return options;
        }

        get assignToOptions(): Array<ILookupItem> {
            const options: Array<ILookupItem> = [];
            const defaultItem = new LookupItem({ id: 0, description: "Unassigned", isArchived: false } as ILookupItem);
            options.push(defaultItem);
            options.push(...this.adminUsers);
            options.push(...this.cqmsUsers);
            return options;
        }

        get editCategoryOptions(): Array<ILookupItem> {
            return utils.selectOptions(this.categoryList.filter(c => !c.isArchived || c.id == this.workflow.categoryID), "None");
        }

        get statusOptions(): Array<ILookupItem> {
            const options: Array<ILookupItem> = [];
            options.push(new LookupItem({id: 0, description: "All Workflow Statuses", isArchived: false }));
            options.push(new LookupItem({id: 1, description: "Initial", isArchived: false }));
            options.push(new LookupItem({id: 2, description: "Resubmission", isArchived: false }));

            if (this.view == WorkflowView.Admin) {
                options.push(new LookupItem({id: 3, description: "Accepted", isArchived: false }));
                options.push(new LookupItem({id: 4, description: "Rejected", isArchived: false }));
            }

            return options;
        }

        get fields(): Array<unknown> {
            return this.view == WorkflowView.Admin ? 
            [
                { key: "select", label: "", width: "25px" },
                { key: "auditNumber", label: "#", width: "25px", class: 'text-center' },
                { key: "fastTrack", label: "", width: "25px", class: 'text-center' },
                { key: "responseSetSubmitted", label: "Submitted" }, 
                { key: "supplierID", label: "Supplier" }, 
                { key: "moduleID", label: "Module" }, 
                { key: "status", label: "Status" },
                { key: "progress", label: "Progress" },
                { key: "previouslyAssignedToUserID", label: "Previous Assignee ", width: "140px" },
                { key: "actions", label: "", width: "50px" }
            ] : 
            [ 
                { key: "select", label: "", width: "25px" },
                { key: "auditNumber", label: "#", width: "25px", class: 'text-center' },
                { key: "fastTrack", label: "", width: "25px", class: 'text-center' },
                { key: "passedToAuditor", label: "Passed to Auditor", width: "150px" },
                { key: "deadline", label: "Deadline", width: "75px" },
                { key: "supplierID", label: "Supplier" }, 
                { key: "moduleID", label: "Module" }, 
                { key: "status", label: "Status" }, 
                { key: "progress", label: "Progress" },
                { key: "assignedToUserID", label: "Assigned To", width: "140px" },
                { key: "actions", label: "", width: "50px" }
            ];
        }

        get canSendEmail(): boolean {
            const canSend = !utils.isEmptyId(this.workflow.assignedToUserID) && this.workflow.assignedToUserID !== this.currentlyAssignedTo;
            return canSend;
        }

        get isAssigned(): boolean {
            return !utils.isEmptyId(this.workflow.assignedToUserID) && this.adminUsers.findIndex(u => u.id == this.workflow.assignedToUserID) ==  -1;
        }

        // watchers

        @Watch("parameters.supplierID")
        onSupplierChanged():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.moduleID")
        onModuleChanged():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.statusID")
        onStatusChanged():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.categoryID")
        onCategoryChanged():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.assignedToUserID")
        onSearchAssignedToChanged():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.previouslyAssignedToUserID")
        onSearchPreviouslyAssignedToUserID():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.submittedDate")
        onSearchSubmittedDate():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.deadlineDate")
        onSearchDeadlineDate():void {
            this.parameters.pageNumber = 1;
            this.search();
        }

        @Watch("parameters.pageNumber")
        onPageChanged():void {
            this.search();
        }

        @Watch("workflow.assignedToUserID")
        onAssignedToChanged():void {
            if (utils.isEmptyId(this.workflow.assignedToUserID) || this.adminUsers.find(u => u.id == this.workflow.assignedToUserID)) {
                this.workflow.passedToAuditor = null;
                this.workflow.deadline = null;
            }
            else if (!utils.hasDateValue(this.workflow.passedToAuditor) && !utils.hasDateValue(this.workflow.deadline)) {
                this.workflow.passedToAuditor = new Date();
            }
        }

        @Watch("workflow.passedToAuditor")
        async onPassedToAuditorChanged():Promise<void> {
            if (utils.hasDateValue(this.workflow.passedToAuditor)) {
                this.workflow.deadline = await apiClient.post("api/workflow/defaultDeadline", { date: this.workflow.passedToAuditor, fastTrack: this.workflow.fastTrack });
            }
        }

        @Watch("workflow.fastTrack")
        async onFastTrackChanged():Promise<void> {
            this.workflow.deadline = await apiClient.post("api/workflow/defaultDeadline", { date: this.workflow.passedToAuditor, fastTrack: this.workflow.fastTrack });
        }

        // methods
        async getLookups():Promise<void> {
            this.itemsPerPage = await apiClient.get("/api/lookup/itemsPerPage")

            const params = new UserSearchParameters();

            let response = await apiClient.post("/api/user/searchLookups", params)
            this.allUsers.push(...response);

            params.isDeleted = TriState.False;
            params.isDormant = TriState.False;
            params.isAuditor = TriState.True;
            params.adminAuditUsers = TriState.False;

            params.role = UserRole.Admin;
            response = await apiClient.post("/api/user/searchLookups", params)
            this.cqmsUsers.push(...response);

            params.role = UserRole.Cqms;
            response = await apiClient.post("/api/user/searchLookups", params)
            this.cqmsUsers.push(...response);

            params.role = 0;
            params.isDormant = TriState.UseDefault;
            params.isAuditor = TriState.UseDefault;
            params.adminAuditUsers = TriState.True;
            response = await apiClient.post("/api/user/searchLookups", params)
            this.adminUsers.push(...response);

            this.cqmsUsers.sort((a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0));
            
            this.supplierList = [];
            response = await apiClient.get("/api/supplier/workflowLookups");
            this.supplierList.push(new LookupItem({ id: 0, description: "All Suppliers", isArchived: false } as ILookupItem));
            this.supplierList.push(...response.map((i: ILookupItem) => new LookupItem(i)));
            
            this.moduleList = [];
            response = await apiClient.get("/api/module/lookups");
            this.moduleList.push(...response.map((i: ILookupItem) => new LookupItem(i)));
            
            this.categoryList = [];
            response = await apiClient.get(`/api/lookup/search/${LookupGroup.WorkflowCategories}`);
            this.categoryList.push(...response.map((i: ILookupItem) => new LookupItem(i)));
        }

        async search():Promise<void> {
            if (!utils.isEmptyId(this.parameters.pageNumber)) {
                this.parameters.view = this.view;
                const response = await apiClient.post("/Api/Workflow/Search", this.parameters);
                this.totalCount = response.count;   
                this.workflows.length = 0;
                this.workflows.push(...response.list.map((w: IWorkflow) => new Workflow(w)));
            }
        }

        clearSearchParams():void {
            utils.resetObject(this.parameters);
            const user = (Authentication.signedInUser() as IUser)
            this.parameters.assignedToUserID = this.view == WorkflowView.Admin ? -1 : user.id;
            this.parameters.pageNumber = 1;
            this.refreshSearch();
        }

        refreshSearch():void {
            //this.parameters.pageNumber = 1
            this.search();
        }

        goToSupplier(item: Workflow): void {
            const route = { 
                path: `${this.isBuyerZone ? '/buyerZone' : ''}/Supplier/${item.supplierID}?goToTab=history`,
                query: { goToTab: "modules" } 
            };
            window.open(route.path, "_blank");
        }

        canEdit(workflow: IWorkflow):boolean {
            return workflow.status == "Initial" || workflow.status == "Resubmission"
        }

        canDismiss():boolean {
            return this.view == WorkflowView.Admin
        }

        async edit(id: number):Promise<void> {
            utils.resetObject(this.workflow);
            const response: IWorkflow = await apiClient.get(`/api/workflow/load?id=${id}`);
            this.workflow.update(response);
            this.currentlyAssignedTo = this.workflow.assignedToUserID;
            this.$v.$reset();
            this.$bvModal.show(this.editDialogID);
        }
        
        async editBulk():Promise<void> {
            utils.resetObject(this.workflow);
            this.$v.$reset();
            
            var fastTrack = this.workflows
                .filter(workflow => this.selectedWorkflows.includes(workflow.responseSetID) && !workflow.fastTrack)
                .length == 0
            this.workflow.fastTrack = fastTrack;

            this.$bvModal.show(this.editDialogID);
        }

        cancel():void {
            this.$bvModal.hide(this.editDialogID);
            utils.resetObject(this.workflow)
        }

        async detailsIncorrectDismissed(event: Event):Promise<void> {
            if (utils.isEmptyId(this.workflow.responseSetID)) return;
            const response = await apiClient.post(`/api/workflow/dismissDetailsIncorrect?id=${this.workflow.responseSetID}`, event);
            if (response.message !== "ok") {
                toastr.warning("Failed to save");
                this.workflow.detailsFlaggedAsIncorrect = true;
            }
            else {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.workflows.find(w => w.responseSetID == this.workflow.responseSetID)!.detailsFlaggedAsIncorrect = false;
            }
        }

        async save(event: Event):Promise<void> {
            if (!this.isValid()) {
                toastr.info("Please fix the highlighted errors");
                return;
            }

            const data = { workflow: this.workflow, responseSetIDs: this.selectedWorkflows }
            const response = await apiClient.post("/api/workflow/save", data, event);
            if (response.message === "ok") {
                toastr.success("Saved");
                this.$bvModal.hide(this.editDialogID);
                this.selectedWorkflows = [];
                this.$emit("saved")
            }
            else {
                toastr.warning("Failed to save");
            }
        }

        // 'delete' is a reserved word
        async deleteItem(workflow: Workflow, event: Event):Promise<void> {
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Are you sure you want to dimiss this workflow?", {
                title: "Dimiss Workflow",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "No",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });

            if (!shouldDelete) return;

            const response = await apiClient.post("/api/workflow/delete", workflow, event);
            toastr.warning(response.message === "ok" ? "Deleted": "Failed to delete");
            this.refreshSearch();
        }

        async showHistory(item: IWorkflow):Promise<void> {
            utils.resetObject(this.workflow);
            this.workflow.update(item);

            this.history.length = 0;
            const response: Array<IWorkflowHistory> = await apiClient.get(`/api/workflow/history?id=${item.responseSetID}`);
            this.history.push(...response.map((h: IWorkflowHistory) => new WorkflowHistory(h)));

            this.$bvModal.show(this.editHistoryID);
        }

        closeHistory():void {
            this.$bvModal.hide(this.editHistoryID);
        } 

        rowClass(value: Workflow):string {
            return value.rowVariant;
        }
        
        //
        // -- validation
        //

        @Validations()
        validations():any {
            const validations = {
                workflow: {} as any
            };

            validations.workflow.passedToAuditor = { dateValid: (value: Date, workflow: Workflow) => !this.isAssigned || utils.hasDateValue(value) };
            validations.workflow.deadline = { dateValid: (value: Date, workflow: Workflow) => !this.isAssigned || (utils.hasDateValue(value) && value > this.workflow.assignedDate) };

            return validations
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        async isValid(): Promise<boolean> {
            this.$v.$reset();
            this.$v.$touch();
            await this.waitForValidation();
            return Promise.resolve(!this.$v.$error);
        }

        // from https://github.com/vuelidate/vuelidate/issues/179
        waitForValidation():Promise<null> {
            return new Promise(resolve => {
                if (this.$v.$error || !this.$v.$pending) {
                    return resolve(null);
                }
                const poll = setInterval(() => {
                    if (!this.$v.$pending) {
                        clearInterval(poll)
                        resolve(null)
                    }
                }, 200);
            })
        }

    }

